import AppUtils from '@control-front-end/utils/utils';
import { cr } from 'mw-style-react';
/**
 * Get hierarchical edges coming out of the nodes
 */
export const getOutputHierarchicalEdges = ({ nodes, edgeTypeId }) =>
  nodes.outgoers(`edge[edgeTypeId = ${edgeTypeId}]`);

/**
 * Get a list of nodes in state
 */
export const getNodesInState = ({ stateNode, cy }) => {
  const targetPolygon = AppUtils.makeRectanglePolygon(stateNode.boundingBox());
  return cy
    ?.elements('node')
    .filter((n) =>
      cr(
        [n.id() === stateNode.id() || n.data('isTrace'), () => false],
        [
          n.data('isStateMarkup'),
          () =>
            AppUtils.checkBoundingBoxInsidePolygon(
              n.boundingBox(),
              targetPolygon,
              1
            ),
        ],
        [
          true,
          () =>
            AppUtils.checkPointInsidePolygon(n.position(), targetPolygon, 1),
        ]
      )
    );
};

export default {
  getOutputHierarchicalEdges,
  getNodesInState,
};
