/* eslint-disable prettier/prettier */
import {
  MAILTO_REGEX,
  RE_UUID_GLOBAL,
  SEARCH_SMART_CHIP_REGEXP,
  URL_REGEXP,
} from '@control-front-end/common/constants/regExp';
import { Utils, Cursor, FileUtils } from 'mw-style-react';
// eslint-disable-next-line import/no-cycle
import AppUtils from '../utils';

export default {
  removeHtmlTags(str = '', unstrip = false) {
    str = str || '';
    const rTag = /<[^>]*>/g; // NOSONAR
    const strToReplace = unstrip
      ? str.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
      : str;
    return strToReplace.replace(rTag, '');
  },

  divToN(str) {
    str = str || '';
    return str.replace(/<div>/gi, '\n').replace(/<\/div>/gi, '');
  },

  correctImgSize(el, callback) {
    const img = new Image();
    const src = el.getAttribute('src');
    img.src = src;
    const maxWidth = Math.round(window.innerWidth * 0.85);
    const maxHeight = Math.round(window.innerHeight * 0.85);
    img.onload = () => {
      const { naturalWidth } = img;
      const { naturalHeight } = img;
      const ratio = Math.min(
        maxWidth / naturalWidth,
        maxHeight / naturalHeight
      );
      callback({
        width: Math.round(naturalWidth * ratio),
        height: Math.round(naturalHeight * ratio),
        naturalWidth,
        naturalHeight,
        src,
      });
    };
  },

  getImgSize(fileName, callback) {
    const maxSize = 1000;
    const image = new Image();
    image.src = AppUtils.makeAppUrl(`/download/${fileName}`);
    // eslint-disable-next-line
    image.onload = function () {
      let height = this.height;
      let width = this.width;
      if (maxSize < Math.max(height, width)) {
        const maxH = height > width;
        const prop = height / width;
        height = maxH ? maxSize : maxSize * prop;
        width = maxH ? maxSize / prop : maxSize;
      }
      callback({ width, height });
    };
  },

  hilightLinks(text = '', highlightEmails = true) {
    text = text.toString();
    // исключаем ссылки уже обернутые в тег <a>
    const replacePattern1 = new RegExp(`(href=[\\"']|s*)${URL_REGEXP}`, 'gim');
    text = text.replace(replacePattern1, (arg1, arg2, arg3) =>
      arg2.length
        ? arg1
        : `<a rel="nofollow noreferrer noopener" class="ext__link" href="${arg3}" target="_blank">${arg3}</a>`.replace(
            '&#8203;',
            ''
          )
    );
    if (highlightEmails) {
      const replacePattern2 = new RegExp(MAILTO_REGEX, 'gim');
      text = text.replace(
        replacePattern2,
        ' <a class="hcc" href="mailto:$1@$2.$3">$1@$2.$3</a> '
      );
    }
    return text;
  },

  removeBbCode(text = '') {
    if (!text) return text;
    const rImg = /\[img=(.*?)\](.*?)\[\/img\]/gi; // NOSONAR
    const rUser = /\[user=(.*?)\](.*?)\[\/user\]/gi; // NOSONAR
    const rChip =
      /\[(event|graph|actor)=(.*?)\](.*?)\[\/(event|graph|actor)\]/gi; // NOSONAR

    function chipReplacer(match, p1, p2, p3) {
      return p3;
    }

    text = text
      .replace(rImg, '')
      .replace(rUser, '@$2')
      .replace(rChip, chipReplacer);
    return text;
  },

  removeImgBbCodeById(text, id) {
    text = text || '';
    const rImg = /\[img=(.*?)\](.*?)\[\/img\]/gi; // NOSONAR

    function imgReplacer(match, p1) {
      return p1 === id ? '' : match;
    }

    text = text.replace(rImg, imgReplacer);
    return text;
  },

  /**
   * Converts HTML to BBCode format.
   *
   * @param text - The HTML text to be converted.
   * @param useElementId - Determines whether to include the 'elementId' attribute in the BBCode.
   *                       If set to 'true', 'elementId' will be added to the corresponding BBCode tags.
   *                       'elementId' is used for retrieving the HTML element to set the cursor
   *                       in event details, facilitating precise interaction with the converted content.
   */

  htmlToBbCode(text = '', useElementId = false) {
    const rImg = /<img.?id="(.*?)".?src="(.*?)alt="(.*?)".*?>/gi; // NOSONAR
    const rEvent =
      /<a(?:\s?id="(.*?)")?.*?data-chip="event".*?href="(.*?)" contenteditable="false">(.*?)<\/a>/gi; // NOSONAR
    const rChip =
      /<a(?:\s?id="(.*?)")?.*?data-chip="(.*?)".*?href="(.*?)" contenteditable="false">(.*?)<\/a>/gi; // NOSONAR
    const rMark =
      // eslint-disable-next-line
      /<mark(?:\s?id="(.*?)")?.*?data-id="(.*?)".*?data-chip="(.*?)" contenteditable="false">(.*?)<\/mark>/gi; // NOSONAR
    const reHtmlSlash = new RegExp('⁄ ', 'gi'); // NOSONAR
    const reHtmlAtSymbol = /@/gi;

    function imgReplacer(match, p1, p2, p3) {
      return `[img=${p1}]${p3}[/img]`;
    }

    function eventReplacer(match, elementId, p1, p2) {
      const eventId = p1.replace('"', '').split('/')[4];
      return elementId && useElementId
        ? `[event=${eventId} elementId=${elementId}]${p2}[/event]`
        : `[event=${eventId}]${p2}[/event]`;
    }

    function chipReplacer(match, elementId, p1, p2, p3) {
      const srcParts = p2.replace('"', '').split('/');
      let objId;
      if (p1 === 'graph') {
        objId = srcParts[4];
      } else if (p1 === 'graphLayer') {
        objId = `${srcParts[4]}_${srcParts[6]}`;
      } else {
        objId = srcParts[srcParts.length - 1];
      }
      return elementId && useElementId
        ? `[${p1}=${objId} elementId=${elementId}]${p3}[/${p1}]`
        : `[${p1}=${objId}]${p3}[/${p1}]`;
    }

    function markReplacer(match, elementId, p1, p2, p3) {
      return elementId && useElementId
        ? `[${p2}=${p1} elementId=${elementId}]${p3}[/${p2}]`
        : `[${p2}=${p1}]${p3}[/${p2}]`;
    }

    text = text
      .replace(reHtmlSlash, '/')
      .replace(reHtmlAtSymbol, '@')
      .replace(rMark, markReplacer)
      .replace(rImg, imgReplacer)
      .replace(rEvent, eventReplacer)
      .replace(rChip, chipReplacer);
    return text;
  },

  removeElementIdInBbCode(text) {
    return text.replace(/\selementId=\d+_\d+/g, '');
  },

  bbCodeToHtml(
    text = '',
    accId = '',
    chip = false,
    maxTitleLength = 0,
    stripTitleHtml = false
  ) {
    text = text ? text.toString() : '';
    const rB = /\[b\](.*?)\[\/b\]/gi; // NOSONAR
    const rBr = /\[br\]/gi; // NOSONAR
    const rColor = /\[color="?(.*?)"?\](.*?)\[\/color\]/gi; // NOSONAR
    const rImg = /\[img=(.*?)\](.*?)\[\/img\]/gi; // NOSONAR
    const rImgSrc = /\[imgSrc=(.*?)\](.*?)\[\/imgSrc\]/gi; // NOSONAR
    const rFile = /\[file=(.*?)(?: elementId=(.*?))?\](.*?)\[\/file\]/gi; // NOSONAR
    const rUser = /\[user=(.*?)(?: elementId=(.*?))?\](.*?)\[\/user\]/gi; // NOSONAR
    const rEvent = /\[event=(.*?)(?: elementId=(.*?))?\](.*?)\[\/event\]/gi; // NOSONAR
    const rGraph = /\[graph=(.*?)(?: elementId=(.*?))?\](.*?)\[\/graph\]/gi; // NOSONAR
    const rGraphLayer =
      /\[graphLayer=(.*?)(?: elementId=(.*?))?\](.*?)\[\/graphLayer\]/gi; // NOSONAR
    const rActor = /\[actor=(.*?)(?: elementId=(.*?))?\](.*?)\[\/actor\]/gi; // NOSONAR
    const rApp =
      /\[application=(.*?)(?: elementId=(.*?))?\](.*?)\[\/application\]/gi; // NOSONAR
    const rChat = /\[chat=(.*?)\](.*?)\[\/chat\]/gi; // NOSONAR

    function makeShortTitle(title) {
      return maxTitleLength && title.length > maxTitleLength
        ? `${title.slice(0, maxTitleLength)}...`
        : title;
    }

    function createIdAttribute(elementId) {
      return elementId ? `id="${elementId}"` : '';
    }

    function makeLink({ url, title, type = 'event', elementId }) {
      return `<a ${createIdAttribute(
        elementId
      )} data-chip="${type}" rel="nofollow noreferrer noopener" target="_blank" href="${url}" contenteditable="false">${
        stripTitleHtml
          ? Utils.stripHtml(makeShortTitle(title))
          : makeShortTitle(title)
      }</a>`;
    }

    function makeMark({ dataId, nick, elementId }) {
      const shortTitle = makeShortTitle(nick);
      return `<mark ${createIdAttribute(
        elementId
      )} data-id="${dataId}" data-chip="user" contenteditable="false">${shortTitle}</mark>`;
    }

    function makeChat({ id, title }) {
      return `<mark id="${id}" data-chip="chat" contenteditable="false">${makeShortTitle(
        title
      )}</mark>`;
    }

    function imgReplacer(match, p1, p2) {
      const url = AppUtils.makeAppUrl(`/download/${p1}`);
      return `<img id="${p1}" src="${url}" alt="${p2}">`;
    }

    function imgSrcReplacer(match, p1, p2) {
      return `<img id="${p1}" src="${p1}" alt="${p2}">`;
    }

    /* eslint-disable max-len */
    function imgReplacerChip(match, p1, p2) {
      return `<div class="event__descr__chip" id="img__${p1}">
            <i class="event__descr__chip__icon" id="img__${p1}">
              <svg width="24" height="24" viewBox="0 0 24 24" id="img__${p1}">
                <path
                  id="img__${p1}"
                  d="M14.951 15.073L10 8 3 18h18l-4-6-2.049 3.073zM3 0h18a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm13 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
              </svg>
            </i>
            <span id="img__${p1}" class="event__descr__chip__label" style="color: inherit;">${p2}</span>
        </div>`;
    }
    /* eslint-enable max-len */

    function fileReplacerChip(match, p1, elementId, p2) {
      const url = AppUtils.makeAppUrl(`/download/${p1}`);
      return makeLink({ url, title: p2, type: 'file', elementId });
    }

    function userReplacer(match, p1, elementId, p2) {
      return makeMark({ dataId: p1, nick: p2, elementId });
    }

    function eventReplacer(match, p1, elementId, p2) {
      const uudidRe = new RegExp(`(${RE_UUID_GLOBAL})`);
      const isEventActor = uudidRe.test(p1);
      const url = `/actors_graph/${accId}/view/${p1}`;
      return isEventActor
        ? makeLink({ elementId, url, title: p2, type: 'event' })
        : p2;
    }

    function graphReplacer(match, p1, elementId, p2) {
      const url = `/actors_graph/${accId}/graph/${p1}/layers/0`;
      return makeLink({ url, title: p2, type: 'graph', elementId });
    }

    function graphLayerReplacer(match, p1, elementId, p2) {
      const [graphFolderId, layerId] = p1.split('_');
      const url = `/actors_graph/${accId}/graph/${graphFolderId}/layers/${layerId}`;
      return makeLink({ url, title: p2, type: 'graphLayer', elementId });
    }

    function actorReplacer(match, p1, elementId, p2) {
      const url = `/actors_graph/${accId}/graph/0/actors/${p1}`;
      const title = p2.replace(/\//g, '&frasl; ').replace(/@/g, '&#64;');
      return makeLink({ url, title, type: 'actor', elementId });
    }

    function appReplacer(match, p1, elementId, p2) {
      const url = `/script/${accId}/view/${p1}/production/index`;
      return makeLink({ url, title: p2, type: 'script', elementId });
    }

    function chatReplacer(match, p1, p2) {
      return makeChat({ id: p1, title: p2 });
    }

    text = text
      .replace(rB, '<b>$1</b>')
      .replace(rBr, '<br/>')
      .replace(rColor, '<span style="color:$1">$2</span>')
      .replace(rImg, chip ? imgReplacerChip : imgReplacer)
      .replace(rImgSrc, chip ? imgReplacerChip : imgSrcReplacer)
      .replace(rFile, fileReplacerChip)
      .replace(rEvent, eventReplacer)
      .replace(rGraph, graphReplacer)
      .replace(rGraphLayer, graphLayerReplacer)
      .replace(rActor, actorReplacer)
      .replace(rApp, appReplacer)
      .replace(rChat, chatReplacer)
      .replace(rUser, userReplacer);
    return Utils.sanitizeHtml(text);
  },

  smileToHtml(str) {
    if (!str) return str;
    const tSpan = /\[span(.*?)\](.*?)\[\/span\]/gi; // NOSONAR
    return str.replace(tSpan, '<span$1>$2</span>');
  },

  removeEmptySmartChips(str) {
    return str.replace(SEARCH_SMART_CHIP_REGEXP, '@$1');
  },

  // Scroll to element
  scrollToElement(id, behavior = 'smooth', block = 'nearest') {
    const targetEl = document.getElementById(id);
    if (!targetEl) return null;
    targetEl.scrollIntoView({ behavior, block });
    return targetEl;
  },

  // Подсветить реакцию
  highlightReaction(id) {
    const reactionEl = this.scrollToElement(`reaction_${id}`);
    if (!reactionEl) return;
    setTimeout(() => reactionEl.classList.add('highlight'), 0);
    setTimeout(() => reactionEl.classList.remove('highlight'), 3000);
  },

  isOutOfView(el, container) {
    container = container || document.body;
    const { top, bottom } = el.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    return top <= containerRect.top || bottom >= containerRect.bottom;
  },

  getSelectedText() {
    let text = '';
    if (typeof window.getSelection !== 'undefined') {
      text = window.getSelection().toString();
    } else if (
      typeof document.selection !== 'undefined' &&
      document.selection.type === 'Text'
    ) {
      text = document.selection.createRange().text;
    }
    return text;
  },

  getInnerHeight(elm) {
    const computed = getComputedStyle(elm);
    const padding =
      parseInt(computed.paddingTop, 10) + parseInt(computed.paddingBottom, 10);
    return elm.clientHeight - padding;
  },

  getInnerWidth(elm) {
    const { width, paddingLeft, paddingRight } = getComputedStyle(elm);
    return (
      parseInt(width, 10) -
      parseInt(paddingLeft, 10) -
      parseInt(paddingRight, 10)
    );
  },

  getRealHeight(elm) {
    let h = 0;
    for (const child of elm.childNodes) {
      h += this.getInnerHeight(child);
    }
    return h;
  },

  getRealWidth(elm) {
    const computed = getComputedStyle(elm);
    return (
      parseInt(computed.width, 10) +
      parseInt(computed.paddingLeft, 10) +
      parseInt(computed.paddingRight, 10)
    );
  },

  makeClearText(html) {
    const regBr = /<br>/gi;
    const regSpan = /<span.*?>(.*?)<\/span>&nbsp;/g; // NOSONAR
    return html.replace(regBr, '\n').replace(regSpan, '$1 ');
  },

  removeNewLines(str, replacer = ' ') {
    const regNewLines = /<br>|<br \/>|<div>|\n/gi;
    return str.replace(regNewLines, replacer);
  },

  makeDescription({
    str = '',
    accId,
    chip = false,
    maxTitleLength = 0,
    snippet = false,
    noNewLines = false,
    noHtmlAttributes = false,
    highlightLinks = true,
  }) {
    const stripStr = Utils.stripHtml(snippet ? str : Utils.nToBr(str), [
      'a',
      'b',
      'i',
      'br',
      'div',
    ]);
    /**
     * Allows all tags but sanitizes dangerous attributes.
     * Preventing cases like: <a href="" onmouseover="alert('XSS')">Link</a>)
     */
    const sanitizedStr = Utils.sanitizeHtml(stripStr, {
      allowedTags: false,
      allowedAttributes: noHtmlAttributes ? {} : undefined,
    });
    const formattedStr = this.bbCodeToHtml(
      highlightLinks ? this.hilightLinks(sanitizedStr) : sanitizedStr,
      accId,
      chip,
      maxTitleLength,
      true
    );
    return noNewLines ? this.removeNewLines(formattedStr) : formattedStr;
  },

  getAllParentWindows() {
    const parentWindows = [];
    let currentWindow = window;
    while (currentWindow !== window.top) {
      parentWindows.push(currentWindow.parent);
      currentWindow = currentWindow.parent;
    }
    return parentWindows;
  },

  getDescriptionInBbCodeWithImages(element, images = []) {
    if (!element || !(element instanceof HTMLElement)) {
      return '';
    }

    const cursorPlaceholder = document.createElement('span');
    cursorPlaceholder.setAttribute('data-cursor', '');

    if (Cursor.getCaretPosition(element) === -1) {
      Cursor.moveCursorToEnd(element);
    }

    Cursor.insertNodeAtCursor(cursorPlaceholder);

    const imagesBbCode = images
      .map((item) => `[img=${item.fileName}]${item.title}[/img]`)
      .join('');

    return AppUtils.htmlToBbCode(element.innerHTML).replace(
      new RegExp(cursorPlaceholder.outerHTML, 'g'),
      imagesBbCode
    );
  },

  scrollToElementEndSetCursor(elementId) {
    if (!elementId) return;
    try {
      const element = document.getElementById(elementId);
      if (!element) return;
      Cursor.setCursorAfterElement(element);
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  },

  isScriptView(pathname) {
    return (
      pathname.indexOf('/script/') !== -1 && pathname.indexOf('/view') !== -1
    );
  },

  async selectFiles(filesSource) {
    // eslint-disable-next-line no-return-await
    return await FileUtils.makeFilesArr(filesSource);
  },

  isMouseOverCanvas(coordinates) {
    if (!coordinates) return false;
    const { x, y } = coordinates;
    const pointedElement = document.elementFromPoint(x, y);
    return pointedElement.tagName.toLowerCase() === 'canvas';
  },
};
