import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useIntl, useNotifications } from 'hooks';
import GridLayout from 'react-grid-layout';
import {
  TextField,
  Button,
  Modal,
  ModalButtons,
  ModalContent,
  Divider,
  Stack,
  Space,
  MenuItem,
  Select,
  ProgressBar,
  withForm,
  Utils,
  Label,
  Icon,
  Checkbox,
  Tooltip,
  Radio,
  RadioItem,
  cr,
} from 'mw-style-react';
import {
  CREATE_ACTOR,
  DASHBOARD_CHART_TYPES,
  DASHBOARD_SOURCE_TYPES,
  DEFAULT_DASHBOARD_LEGEND,
  DASHBOARD_RANGES,
  DEFAULT_DASHBOARD,
  GET_DASHBOARD_ACCOUNTS,
  UPDATE_ACTOR,
  DASHBOARD_VIEW_MODES,
  LINED_CHART_TYPES,
} from '@control-front-end/common/constants/graphActors';
import { COUNTER_TYPE } from '@control-front-end/common/constants/actorAccounts';
import AppUtils from '@control-front-end/utils/utils';
import { FilterAccPairSelect } from 'components';
import ChartAccountSelect from './components/ChartAccountSelect';
import DashboardFromActorFilter from './components/DashboardFromActorFilter';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateDashboard.scss'; // NOSONAR

const CHART_TYPES = Object.keys(DASHBOARD_CHART_TYPES).map(
  (key) => DASHBOARD_CHART_TYPES[key]
);
const SOURCE_TYPES = Object.keys(DASHBOARD_SOURCE_TYPES).map(
  (key) => DASHBOARD_SOURCE_TYPES[key]
);
const LABEL_MAX_LENGTH = 32;
const MAX_ACCOUNTS = 50;

/**
 * Check selected accounts to be unique
 * Actor's account pair can be selected only once (no matter which income type)
 * @param accounts
 * @param defaultAccount
 * @param withIncomeType
 */
const checkAccountsDuplicates = (accounts, defaultAccount, withIncomeType) => {
  const newDuplicates = [];
  const metrics = accounts
    .filter(({ account }) => Boolean(account))
    .map((acc) => ({
      ...acc,
      metricKey: `${acc.actor.id}+${
        defaultAccount?.nameId || acc.account.nameId
      }+${defaultAccount?.currencyId || acc.account.currencyId}+${
        withIncomeType ? acc.incomeType : ''
      }`,
    }));

  const groupedMetrics = AppUtils.groupBy(metrics, 'metricKey');
  Object.keys(groupedMetrics).forEach((key) => {
    if (groupedMetrics[key].length === 1) return;
    const [, ...duplicates] = groupedMetrics[key];
    duplicates.forEach((acc) => {
      newDuplicates.push(acc.id);
    });
  });
  return newDuplicates;
};

function CreateDashboardBase(props) {
  const {
    data,
    callback,
    values,
    handleOnChange,
    visibility,
    onClose,
    isSubmit,
  } = props;
  const t = useIntl();
  const dispatch = useDispatch();
  const { actorId, onSaveActor } = data;
  const [layout, setLayout] = useState([]);
  const [activeDragging, setActiveDragging] = useState(false);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [colors, setColors] = useState(() =>
    values?.accounts?.map((account) => account.color)
  );

  const isFirstRenderRef = useRef(true);
  const accountsRef = useRef(values?.accounts);
  accountsRef.current = values?.accounts;
  const [duplicates, setDuplicates] = useState([]);
  const [defaultAccount, setDefaultAccount] = useState(
    values.defaultAccount || {}
  );
  const { showNotification } = useNotifications();
  const systemForms = useSelector((state) => state.systemForms);
  const dashboardsFormId = systemForms.dashboards.id;
  const modalLabel = actorId ? t(mes.editChart) : t(mes.addChart);
  const singleAccSelected = values.accounts.length === 1;
  const isLineChart = LINED_CHART_TYPES.includes(values.chartType);
  const [regeneratingColors, setRegeneratingColors] = useState(false);

  useEffect(() => {
    if (isLineChart && values.range === 'allTime') {
      handleOnChange({ id: 'range', value: 'today' });
    } else if (isLineChart && values.range === 'realTime') {
      handleOnChange({ id: 'range', value: 'lineRealTime' });
    } else if (!isLineChart && values.range === 'lineRealTime') {
      handleOnChange({ id: 'range', value: 'realTime' });
    }
  }, [isLineChart]);

  const makeDefaultAccounts = () => {
    return [
      {
        id: AppUtils.udid(),
        color: AppUtils.getRandomColorByPalette(),
      },
    ];
  };

  /**
   * Load dashboard accounts
   */
  useEffect(() => {
    if (!actorId) return;
    setAccountsLoading(true);
    const dataSource =
      typeof data?.data?.source === 'string'
        ? AppUtils.jsonParse(data?.data?.source, null)
        : data?.data?.source;
    dispatch({
      type: GET_DASHBOARD_ACCOUNTS.REQUEST,
      payload: {
        actorId,
        sourceAccounts: values.legend.incomeType ? dataSource?.accounts : [],
      },
      callback: ({ accounts }) => {
        const withIncomeType = values.legend.incomeType;
        handleOnChange({
          id: 'accounts',
          value:
            accountsRef.current?.map((item) => ({
              ...item,
              ...(accounts.find(
                (acc) =>
                  `${acc.actor.id}_${acc.account.currencyId}_${
                    acc.account.nameId
                  }_${withIncomeType ? acc.incomeType : ''}` ===
                  `${item.actorId}_${item.currencyId}_${item.nameId}_${
                    withIncomeType ? item.incomeType : ''
                  }`
              ) || {}),
            })) || accounts,
        });
        setAccountsLoading(false);
      },
      errorCallback: () => {
        handleOnChange({
          id: 'accounts',
          value: makeDefaultAccounts(),
        });
        setAccountsLoading(false);
      },
    });
  }, [actorId]);

  useEffect(() => {
    const accountsLayout = values.accounts.map(({ id }, index) => ({
      i: id,
      x: 0,
      y: index,
      w: 1,
      h: 1,
    }));
    setLayout(accountsLayout);
  }, []);

  useEffect(() => {
    // Skipp first render and trigger call-back when something changed
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }
    if (values.sourceType === DASHBOARD_SOURCE_TYPES.MANUAL) {
      handleOnChange({
        id: 'accounts',
        value: makeDefaultAccounts(),
      });
    } else {
      setDefaultAccount({});
    }
  }, [values.sourceType, isFirstRenderRef]);

  const axesDisplay = useMemo(
    () =>
      values.chartType === DASHBOARD_CHART_TYPES.BAR ||
      values.chartType === DASHBOARD_CHART_TYPES.LINE,
    [values.chartType]
  );

  useEffect(() => {
    // run only to re-check duplicates
    if (!duplicates.length) return;
    const filledAccounts = values.accounts.filter(({ account, actor }) =>
      Boolean(account && actor?.id)
    );
    setDuplicates(
      checkAccountsDuplicates(
        filledAccounts,
        defaultAccount,
        values.legend.incomeType
      )
    );
  }, [values.accounts, defaultAccount, values.legend.incomeType]);

  const handleChangeOrder = (value) => {
    const newLayout = [...value];
    setLayout(newLayout);
    const sortedLayout = AppUtils.sort(newLayout, 'y');
    const newAccounts = sortedLayout.map(({ i }) =>
      values.accounts.find((acc) => acc.id === i)
    );
    handleOnChange({ id: 'accounts', value: newAccounts });
  };

  const handleRegenerateColors = () => {
    if (regeneratingColors) return;
    setRegeneratingColors(true);
    const randomColors = AppUtils.getRandomColors(colors.length);
    setColors(randomColors);
    const newAccounts = values.accounts.map((account, index) => ({
      ...account,
      color: randomColors[index],
    }));
    handleOnChange({ id: 'accounts', value: newAccounts });
    setTimeout(() => setRegeneratingColors(false), 500);
  };

  const handleSubmit = () => {
    const {
      title,
      accounts,
      counterType,
      chartType,
      sourceType,
      dynamicSource,
      range,
      showTotal = true,
      legend,
      orderValue,
      yAxisLabel,
      chartViewMode,
      displayChartDataLabels,
    } = values;
    const { actorId, laId, position } = data;

    const newDefaultAccount =
      defaultAccount?.nameId && defaultAccount?.currencyId
        ? defaultAccount
        : null;

    const filledAccounts = accounts.filter(({ account, actor }) =>
      Boolean((account || newDefaultAccount) && actor?.id)
    );
    if (
      !filledAccounts.length &&
      sourceType === DASHBOARD_SOURCE_TYPES.MANUAL
    ) {
      showNotification('error', t(mes.noAccountsSelected));
      return;
    }
    const newDuplicates = checkAccountsDuplicates(
      filledAccounts,
      newDefaultAccount,
      legend.incomeType
    );
    setDuplicates(newDuplicates);
    if (newDuplicates.length) {
      showNotification('error', t(mes.duplicateAccounts));
      return;
    }
    const filter = dynamicSource?.filter || {};
    if (
      values.sourceType === DASHBOARD_SOURCE_TYPES.ACTOR_FILTER &&
      (!filter.accountNameId || !filter.currencyId)
    ) {
      showNotification('error', t(mes.selectedFilterHasNoAccount));
      return;
    }
    const source = {
      defaultAccount: newDefaultAccount,
      accounts: filledAccounts.map(({ account, actor, incomeType, color }) => ({
        actorId: actor.id,
        account: newDefaultAccount,
        nameId: defaultAccount?.nameId || account.nameId,
        currencyId: defaultAccount?.currencyId || account.currencyId,
        actor: Utils.pick(actor, ['id', 'title']),
        incomeType,
        color,
      })),
      counterType,
      chartType,
      sourceType,
      dynamicSource,
      range: DASHBOARD_RANGES.includes(range) ? range : DEFAULT_DASHBOARD.range,
      showTotal,
      orderValue: isLineChart ? 'default' : orderValue,
      legend,
      yAxisLabel,
      displayChartDataLabels,
    };

    if (isLineChart) {
      source.chartViewMode = chartViewMode;
    }

    dispatch({
      type: actorId ? UPDATE_ACTOR.REQUEST : CREATE_ACTOR.REQUEST,
      payload: {
        id: laId,
        actorId,
        title,
        position,
        formId: dashboardsFormId,
        formData: {
          source: JSON.stringify(source),
        },
      },
      callback: (actor) => {
        onClose();
        if (callback) callback(actor);
        if (onSaveActor) onSaveActor(dashboardsFormId, actor, actor.data);
      },
    });
  };

  const handleCustomizeLegend = ({ key, value }) => {
    const newLegend = structuredClone(values.legend);
    newLegend[key] = value;
    handleOnChange({ id: 'legend', value: newLegend });
  };

  const handleAddAccount = () => {
    const randomColor = AppUtils.getRandomColorByPalette(colors);
    setColors((colors) => [...colors, randomColor]);
    handleOnChange({
      id: 'accounts',
      value: [
        ...values.accounts,
        {
          id: AppUtils.udid(),
          color: randomColor,
        },
      ],
    });
  };

  const handleChangeAccount = (index, data) => {
    const copyTo = values.accounts.slice();
    const updatedAcc = copyTo[index];
    copyTo.splice(index, 1, { ...updatedAcc, ...data });
    handleOnChange({ id: 'accounts', value: copyTo });
  };

  const handleRemoveAccount = (index) => {
    const copyTo = values.accounts.slice();
    copyTo.splice(index, 1);
    handleOnChange({ id: 'accounts', value: copyTo });
  };

  const renderAccountRow = (acc, index) => {
    return (
      <div
        styleName="l.modal__content__item"
        id={acc.id}
        key={acc.id}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="drag">
          <Icon type="drag" size="large" />
        </div>
        <ChartAccountSelect
          item={acc}
          error={duplicates.includes(acc.id)}
          showLabels={false}
          isRemovable={!singleAccSelected}
          onChange={(acc) => handleChangeAccount(index, acc)}
          onRemove={() => handleRemoveAccount(index)}
          defaultAccount={
            defaultAccount.nameId && defaultAccount.currencyId
              ? defaultAccount
              : null
          }
        />
      </div>
    );
  };

  const renderManualDashboardSettings = () =>
    accountsLoading ? (
      <div styleName="l.modal__content__loader">
        <ProgressBar type="circle" size="large" />
      </div>
    ) : (
      <>
        <div styleName="l.modal__content__header">
          <Stack.H size="xsmall">
            <Label fontWeight="semibold" value={t(mes.color)} />
            <Tooltip topLevel position="top" value={t(mes.regenerateColors)}>
              <button
                type="button"
                className={cn(l.regenerateBtn, {
                  [l.animateRegenarateBtn]: regeneratingColors,
                })}
                onClick={handleRegenerateColors}
              >
                <Icon type="repeat" size="medium" />
              </button>
            </Tooltip>
          </Stack.H>
          <Label fontWeight="semibold" value={t(mes.actor)} />
          <Label fontWeight="semibold" value={t(mes.account)} />
          <Label fontWeight="semibold" value={t(mes.type)} />
        </div>
        <GridLayout
          className="layout"
          layout={layout}
          cols={1}
          rowHeight={32}
          width={920}
          isDraggable={!singleAccSelected}
          isBounded
          draggableHandle=".drag"
          onLayoutChange={handleChangeOrder}
          onDragStart={() => setActiveDragging(true)}
          onDragStop={() => setActiveDragging(false)}
        >
          {values.accounts.map((acc, index) => renderAccountRow(acc, index))}
        </GridLayout>
        <Button
          styleName="l.modal__content__btn"
          type="text"
          size="smallplus"
          fontWeight="normal"
          icon="add"
          label={t(mes.addSource)}
          onClick={handleAddAccount}
          visibility={
            values.accounts.length < MAX_ACCOUNTS ? 'visible' : 'hidden'
          }
        />
      </>
    );

  const renderDynamicDashboardSettings = () => {
    return (
      <DashboardFromActorFilter
        value={values.dynamicSource}
        onChange={(value) => {
          handleOnChange({ id: 'dynamicSource', value });
        }}
        onAccountsChange={(value) => {
          handleOnChange({ id: 'accounts', value });
        }}
      />
    );
  };

  return (
    <Modal
      styleName="m.modal l.modal"
      size="xlarge"
      onClose={onClose}
      label={modalLabel}
      visibility={visibility}
    >
      <ModalContent
        id="modalContent"
        styleName={cn('m.modal__content l.modal__content', {
          dragging: activeDragging,
        })}
      >
        <Space top bottom size={Space.SIZE.large}>
          <Stack size={Stack.SIZE.xxlarge}>
            <Stack.H
              styleName="l.modal__content__row"
              fullWidth
              alignItems="center"
              justifyContent="spaceBetween"
              size={Stack.SIZE.xsmall}
            >
              <TextField
                id="title"
                styleName="m.textfield"
                bordered={true}
                label={t(mes.title)}
                value={values.title}
                unspaced
                autoFocus={!actorId}
                autoSelect={!actorId}
                onChange={handleOnChange}
              />
              <Select
                id="range"
                styleName="m.select"
                bordered={true}
                label={t(mes.chartPeriod)}
                unspaced
                value={
                  values.range && DASHBOARD_RANGES.includes(values.range)
                    ? values.range
                    : DEFAULT_DASHBOARD.range
                }
                onChange={handleOnChange}
                endAdornment={
                  <Tooltip topLevel value={t(mes.dashboardOriginalDateTooltip)}>
                    <Icon
                      styleName="l.modal__content__checkbox__info"
                      type="info"
                    />
                  </Tooltip>
                }
              >
                {DASHBOARD_RANGES.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    label={t(mes[`range_${item}`])}
                    visibility={
                      (isLineChart && item === 'allTime') ||
                      (isLineChart && item === 'realTime') ||
                      (!isLineChart && item === 'lineRealTime')
                        ? 'hidden'
                        : 'visible'
                    }
                  />
                ))}
              </Select>
            </Stack.H>
            <Stack styleName="l.modal__content__row" fullWidth size="none">
              <Select
                id="chartType"
                styleName="m.select"
                className={l.selectFullWidth}
                bordered={true}
                label={t(mes.chartType)}
                value={values.chartType}
                onChange={handleOnChange}
                unspaced
              >
                {CHART_TYPES.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    label={Utils.toUpperLatter(item)}
                  />
                ))}
              </Select>
              {cr([
                isLineChart,
                <Space top size={Space.SIZE.small}>
                  <Radio
                    value={values.chartViewMode}
                    id="chartViewMode"
                    align="horizontal"
                    onChange={handleOnChange}
                    visibility={isLineChart ? 'visible' : 'hidden'}
                  >
                    <RadioItem
                      label={t(mes.lineDashboardDynamic)}
                      value={DASHBOARD_VIEW_MODES.default}
                    />
                    <RadioItem
                      label={t(mes.lineDashboardBalanceChanges)}
                      value={DASHBOARD_VIEW_MODES.balanceChanges}
                    />
                  </Radio>
                </Space>,
              ])}
            </Stack>
            <Stack.H
              styleName="l.modal__content__row"
              fullWidth
              alignItems="center"
              justifyContent="spaceBetween"
              size={Stack.SIZE.xsmall}
            >
              <Select
                id="counterType"
                styleName="m.select"
                bordered={true}
                label={t(mes.chartCounterType)}
                value={values.counterType}
                onChange={handleOnChange}
                unspaced
              >
                {Object.values(COUNTER_TYPE).map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    label={t(mes[`counterType_${item}`])}
                  />
                ))}
              </Select>
              <FilterAccPairSelect
                label={t(mes.defaultAccountTitle)}
                color={Select.COLOR.white}
                popoverOnTop
                onChange={setDefaultAccount}
                placeholder={t(mes.defaultAccountPlaceholder)}
                visibility={
                  values.sourceType === DASHBOARD_SOURCE_TYPES.MANUAL
                    ? 'visible'
                    : 'disabled'
                }
                {...(defaultAccount || {})}
                endAdornment={
                  <Tooltip topLevel value={t(mes.defaultAccountTooltip)}>
                    <Icon
                      styleName="l.modal__content__checkbox__info"
                      type="info"
                    />
                  </Tooltip>
                }
              />
            </Stack.H>
            <Stack.H
              styleName="l.modal__content__row"
              fullWidth
              alignItems="center"
              justifyContent="spaceBetween"
              size={Stack.SIZE.xsmall}
            >
              <Select
                id="sourceType"
                styleName="m.select"
                className={l.selectFullWidth}
                bordered={true}
                label={t(mes.sourceType)}
                value={values.sourceType || 'manual'}
                onChange={handleOnChange}
                unspaced
              >
                {SOURCE_TYPES.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    label={t(mes[`sourceType_${item}`])}
                  />
                ))}
              </Select>
            </Stack.H>
          </Stack>
        </Space>
        <Stack.H size={Stack.SIZE.xlarge}>
          <Stack.H alignItems="center" size={Stack.SIZE.xsmall}>
            <Label fontWeight="semibold" value={t(mes.legendSettings)} />
            <Tooltip topLevel value={t(mes.legendInfo)}>
              <Icon styleName="l.modal__content__checkbox__info" type="info" />
            </Tooltip>
          </Stack.H>
          {Object.keys(DEFAULT_DASHBOARD_LEGEND).map((item) => (
            <Checkbox
              key={item}
              styleName="l.modal__content__checkbox"
              value={values.legend ? values.legend[item] : true}
              onChange={({ value }) =>
                handleCustomizeLegend({ key: item, value })
              }
            >
              <Label value={t(mes[`l_${item}`])} />
            </Checkbox>
          ))}
        </Stack.H>
        {axesDisplay ? (
          <Space bottom>
            <Stack.H alignItems="center" size={Stack.SIZE.xsmall}>
              <Label fontWeight="semibold" value={t(mes.yAxisLabel)} />
              <Tooltip topLevel value={t(mes.yAxisInfo)}>
                <Icon
                  styleName="l.modal__content__checkbox__info"
                  type="info"
                />
              </Tooltip>
              <TextField
                id="yAxisLabel"
                styleName="m.textfield l.modal__content__axis"
                bordered={true}
                unspaced
                length={LABEL_MAX_LENGTH}
                value={values.yAxisLabel}
                placeholder={`Up to ${LABEL_MAX_LENGTH} characters`}
                onChange={handleOnChange}
              />
            </Stack.H>
          </Space>
        ) : null}
        <Divider styleName="l.modal__content__divider" />
        <div styleName="l.modal__content__list">
          {!values.sourceType ||
          values.sourceType === DASHBOARD_SOURCE_TYPES.MANUAL
            ? renderManualDashboardSettings()
            : renderDynamicDashboardSettings()}
          <Divider styleName="l.modal__content__divider" />
          <Stack.H
            alignItems="center"
            justifyContent="spaceBetween"
            size={Stack.SIZE.xxlarge}
          >
            <Checkbox
              id="showTotal"
              styleName="l.modal__content__checkbox"
              value={values.showTotal}
              onChange={handleOnChange}
              visibility="visible"
            >
              <Label value={t(mes.displayTotal)} />
            </Checkbox>
            {!isLineChart ? (
              <Stack.H horizontal alignItems="center" size={Stack.SIZE.xsmall}>
                <Label fontWeight="semibold" value={t(mes.sorting)} />
                <Select
                  id="orderValue"
                  styleName="m.select"
                  unspaced
                  bordered
                  value={values.orderValue || 'default'}
                  onChange={handleOnChange}
                  popoverOnTop
                >
                  <MenuItem value="default" label={t(mes.order_default)} />
                  <MenuItem value="asc" label={t(mes.order_asc)} />
                  <MenuItem value="desc" label={t(mes.order_desc)} />
                </Select>
              </Stack.H>
            ) : null}
            <Space bottom size={Space.SIZE.small}>
              <Checkbox
                id="displayChartDataLabels"
                styleName="l.modal__content__checkbox"
                value={values.displayChartDataLabels}
                onChange={handleOnChange}
              >
                <Label value={t(mes.displayValues)} />
              </Checkbox>
            </Space>
          </Stack.H>
        </div>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={modalLabel}
          size="large"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

const CreateDashboard = withForm(
  {
    mapPropsToValues(props) {
      const formData = props.data || {};
      const { actorId, title = '', data = {} } = formData;
      if (!actorId) {
        return {
          ...DEFAULT_DASHBOARD,
          accounts: [
            {
              id: AppUtils.udid(),
              color: AppUtils.getRandomColorByPalette(),
            },
          ],
        };
      }
      const { source = '{}' } = data;
      const parsedSource =
        typeof source === 'string' ? JSON.parse(source) : source;
      return {
        title,
        ...parsedSource,
        showTotal: AppUtils.isUndefined(parsedSource.showTotal)
          ? true
          : parsedSource.showTotal,
        accounts: (parsedSource.accounts || [{}]).map((acc) => ({
          ...acc,
          id: AppUtils.udid(),
          color: acc.color || AppUtils.getRandomColorByPalette(),
        })),
        displayChartDataLabels:
          parsedSource.displayChartDataLabels ??
          DEFAULT_DASHBOARD.displayChartDataLabels,
        chartViewMode:
          parsedSource.chartViewMode || DEFAULT_DASHBOARD.chartViewMode,
        legend: AppUtils.isUndefined(parsedSource.legend)
          ? DEFAULT_DASHBOARD_LEGEND
          : parsedSource.legend,
      };
    },
  },
  CreateDashboardBase
);

CreateDashboardBase.propTypes = {
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleOnChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
};

export default CreateDashboard;
