import PropTypes from 'prop-types';

export const privsPropType = PropTypes.shape({
  view: PropTypes.bool,
  modify: PropTypes.bool,
  remove: PropTypes.bool,
  sign: PropTypes.bool,
  execute: PropTypes.bool,
});

const CY_GROUP_TYPE = { nodes: 'nodes', edges: 'edges' };

export const cyNodePropType = {
  // 'nodes' for a node, 'edges' for an edge
  // NB the group field can be automatically inferred for you but specifying it
  // gives you nice debug messages if you mis-init elements
  group: PropTypes.oneOf(Object.values(CY_GROUP_TYPE)).isRequired,

  // element data (put json serialisable dev data here)
  data: PropTypes.object.isRequired,

  // scratchpad data (usually temp or nonserialisable data)
  scratch: PropTypes.object.isRequired,

  // the model position of the node (optional on init, mandatory after)
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),

  // whether the element is selected (default false)
  selected: PropTypes.bool.isRequired,

  // whether the selection state is mutable (default true)
  selectable: PropTypes.bool.isRequired,

  // when locked a node's position is immutable (default false)
  locked: PropTypes.bool.isRequired,

  // whether the node can be grabbed and moved by the user
  grabbable: PropTypes.bool.isRequired,

  // whether dragging the node causes panning instead of grabbing
  pannable: PropTypes.bool.isRequired,

  // an array (or a space separated string) of class names that the element has
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,

  // DO NOT USE THE `style` FIELD UNLESS ABSOLUTELY NECESSARY
  // USE THE STYLESHEET INSTEAD
  style: PropTypes.object,
};

/**
 * Node fields that are not related to ator data.
 * Each separate node should have its own data in this fields
 */
export const graphElementOwnFieldsPropType = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  laId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isTrace: PropTypes.string,
  replaceAll: PropTypes.bool,
  isNonInteractive: PropTypes.bool,
  cellPosition: PropTypes.string,
  polygon: PropTypes.string,
  areaPicture: PropTypes.string,
};
