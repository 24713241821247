import React from 'react';
import { DateUtils, Utils } from 'mw-style-react';
import {
  ACTOR_MODEL_HISTORY_FIELDS,
  DATE_FORMAT_2,
  DATE_FORMAT_4,
} from 'constants';
import {
  KEY_FORM_PREFIX,
  FIELD_PATH_PREFIX,
} from '@control-front-end/common/constants/forms';
import AppUtils from './utils';

/**
 * Утилиты для работы с формами
 */
const FormUtils = {
  // Получить все class для одной секции
  getSectionItems(section) {
    return section.content || [];
  },

  // Получить классы, value которых не надо
  // отправлять на сервер
  getNoValueClass() {
    return ['button', 'label', 'link', 'image'];
  },

  getAllItemClasses() {
    return [
      'edit',
      'select',
      'label',
      'image',
      'button',
      'link',
      'calendar',
      'radio',
      'check',
    ];
  },

  getFormItems(form) {
    let allItems = [];
    form.sections.forEach((section) => {
      allItems = allItems.concat(this.getSectionItems(section));
    });
    return allItems;
  },

  // Получить объект с заполнеными значения классов для всей формы
  getFormData(form) {
    const noValueClass = this.getNoValueClass();
    const isVisible = (v) => v === 'visible' || !v;
    const hasValue = (v) => {
      if (typeof v === 'object') {
        return Object.keys(v).every((key) => v[key]?.length);
      }
      return !AppUtils.isUndefined(v);
    };
    // Формируем массив всех visible items формы
    const formItems = form.sections
      .map((i) => FormUtils.getSectionItems(i))
      .reduce((acc, i) => acc.concat(i), [])
      .filter(
        (i) =>
          !noValueClass.includes(i.class) &&
          (isVisible(i.visibility) || hasValue(i.value))
      );
    // Пустые значения в форме
    for (const item of formItems) {
      if (!AppUtils.isUndefined(item.value)) continue;
      if (item.class === 'edit') {
        item.value = '';
      } else if (item.class === 'check') {
        item.value = false;
      }
    }
    // Собираем объект key:val
    return formItems.reduce((acc, i) => ({ ...acc, [i.id]: i.value }), {});
  },

  stripHtml(str) {
    const tagsToReplace = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
    };
    return (
      str
        .replace(/[&<>]/g, (tag) => tagsToReplace[tag] || tag)
        // eslint-disable-next-line no-useless-escape
        .replace(/\&amp\;reg\;/g, '&reg;') // NOSONAR
    );
  },

  bbCodeToHtml(text = '') {
    text = this.stripHtml(text);
    const rB = /\[b\](.*?)\[\/b\]/gi;
    const rI = /\[i\](.*?)\[\/i\]/gi;
    const rU = /\[u\](.*?)\[\/u\]/gi;
    const rBr = /\[br\]/gi;
    text = text
      .replace(rB, '<b>$1</b>')
      .replace(rI, '<i>$1</i>')
      .replace(rU, '<u>$1</u>')
      .replace(rBr, '<br/>');
    return text;
  },

  // Check if form item has empty value
  isEmptyValue(className, itemValue, options) {
    switch (className) {
      case 'check':
        return !AppUtils.toBool(itemValue);
      case 'radio':
        return !options.some((i) => i.value === itemValue);
      default:
        if (typeof itemValue === 'object') {
          return Array.isArray(itemValue)
            ? itemValue.length === 0
            : Object.keys(itemValue).length === 0;
        }
        return AppUtils.isUndefined(itemValue);
    }
  },

  isMismatchRegex(value, regexp) {
    const reqEx = regexp ? new RegExp(regexp) : undefined;
    return reqEx && !reqEx.test(value);
  },

  makeHistoryFields(forms) {
    const fields = ACTOR_MODEL_HISTORY_FIELDS.map((i) => ({
      value: i,
      title: Utils.toUpperLatter(i),
    }));
    for (const f of forms) {
      const formsFields = FormUtils.getFormItems(f).map((item) => ({
        value: f.isRoot ? `data.${item.id}` : `data.__form__${f.id}:${item.id}`,
        title: item.title,
        formId: f.id,
        formTitle: f.title,
      }));
      fields.push(...formsFields);
    }
    return fields;
  },

  // Кликабельный чип актора
  getActorChip(
    actor,
    accId,
    { interactiveChips = true, data = {} } = {},
    index = 0
  ) {
    const { value: actorId, title: actorTitle } = actor;
    const actorBbCode = `[actor=${actorId}]${Utils.stripHtml(
      actorTitle
    )}[/actor]`;
    const dataAttributes = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [`data-${key}`, value])
    );
    const html = AppUtils.hilightLinks(
      AppUtils.bbCodeToHtml(actorBbCode, accId, true),
      false
    );
    return (
      <div
        key={`${actorId}_${index}`}
        style={interactiveChips ? null : { pointerEvents: 'none' }}
        dangerouslySetInnerHTML={{ __html: html }}
        {...dataAttributes}
      />
    );
  },

  // Кликабельный чип пользователя
  getUserChip(actor, accId, { interactiveChips = true } = {}, index = 0) {
    const { value: userId, title: nick } = actor;
    const actorBbCode = `[user=${userId}]${Utils.stripHtml(nick)}[/user]`;
    const html = AppUtils.hilightLinks(
      AppUtils.bbCodeToHtml(actorBbCode, accId, true),
      false
    );
    return (
      <div
        key={`${userId}_${index}`}
        style={interactiveChips ? null : { pointerEvents: 'none' }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  },

  getValueFromOptions(value, options) {
    if (!Array.isArray(options)) return value.toString();
    return options
      .filter((i) => i.value === value)
      .map((i) => i.title)
      .join(', ');
  },

  // форматированный список значений Select/Radio/Autocomplete
  getValuesList(values, accId, options) {
    if (!values.length) return null;
    if (values[0].type === 'actor')
      return values.map((i, index) =>
        this.getActorChip(i, accId, options, index)
      );
    if (values[0].type === 'workspaceMembers')
      return values.map((i, index) =>
        this.getUserChip(i, accId, options, index)
      );
    return values.map((i) => i.title).join(', ');
  },

  // получить form field value class по типу данных
  getFormFieldValueClass(value) {
    function isObject(value) {
      return typeof value === 'object';
    }

    if (
      Array.isArray(value) &&
      isObject(value[0]) &&
      'value' in value[0] &&
      'title' in value[0]
    ) {
      return 'multiSelect';
    }

    if (
      isObject(value) &&
      'endDate' in value &&
      'sendInvite' in value &&
      'startDate' in value &&
      'timeZoneOffset' in value
    ) {
      return 'calendar';
    }
    if (typeof value === 'boolean') {
      return 'check';
    }
    return null;
  },

  // форматированное значение элемента шаблона
  getFormItemValue(item, accId, { interactiveChips, data, ...rest } = {}) {
    let value;
    const safeItemClass = item.class || this.getFormFieldValueClass(item.value);
    switch (safeItemClass) {
      case 'radio':
      case 'select':
      case 'multiSelect':
        value = Array.isArray(item.value)
          ? this.getValuesList(item.value, accId, { interactiveChips, data })
          : this.getValueFromOptions(item.value, item.options);
        break;
      case 'check':
        value = item.value === 'true' || item.value === true ? 'Yes' : 'No';
        break;
      case 'calendar':
        const format = item.extra?.time ? DATE_FORMAT_4 : DATE_FORMAT_2;
        value = AppUtils.isNumeric(item.value)
          ? DateUtils.toDate(item.value, format)
          : AppUtils.makeCalendarItemValue(item.value, format);
        break;
      case 'link':
        value = (
          <a target="_blank" id={item.id} href={item.value}>
            {item.title}
          </a>
        );
        break;
      case 'upload':
        value = item.value.map((i) => {
          const fileChip = AppUtils.bbCodeToHtml(
            `[file=${i.fileName}]${i.title}[/file]`,
            accId
          );
          return (
            <div
              style={interactiveChips ? null : { pointerEvents: 'none' }}
              dangerouslySetInnerHTML={{ __html: fileChip }}
              {...rest}
            />
          );
        });
        break;
      case 'image':
        value = (
          <div
            style={interactiveChips ? null : { pointerEvents: 'none' }}
            dangerouslySetInnerHTML={{
              __html: AppUtils.bbCodeToHtml(
                `[imgSrc=${item.value}]${item.value}[/imgSrc]`,
                accId
              ),
            }}
            {...rest}
          />
        );
        break;
      case 'button':
        value = null;
        break;
      default:
        let html = item.value?.length ? item.value : '-';
        if (item.type === 'password') {
          html = '************';
        } else if (item.value && typeof item.value === 'string') {
          html = AppUtils.hilightLinks(Utils.stripHtml(item.value));
        } else if (item.value?.length && typeof item.value === 'object') {
          html = JSON.stringify(item.value);
        }
        value = (
          <div
            {...rest}
            key={`${item.id}_value`}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        );
        break;
    }
    return value;
  },

  checkDateItemError(formItem) {
    const { value, required, extra } = formItem;
    if (required && !value.startDate) return true;
    const { startDate, endDate } = value;
    return (
      (startDate && extra?.minDate && startDate < extra.minDate) ||
      (endDate && extra?.maxDate && endDate > extra.maxDate)
    );
  },

  // Получить тип источника данных поля
  getOptionSourceType(item) {
    const extra = item.extra || {};
    const optionsSource = extra.optionsSource || {};
    return optionsSource.type;
  },

  // Парсинг ключа формы
  parseFormItemKey(key) {
    const decodeKey = decodeURIComponent(key);
    const split = decodeKey.split(':');
    if (split.length < 2)
      return { itemId: decodeKey.replace(FIELD_PATH_PREFIX, '') };
    const formId = split[0]
      .replace(FIELD_PATH_PREFIX, '')
      .replace(KEY_FORM_PREFIX, '');
    return { itemId: split[1], formId };
  },

  // Найти в массиве форм необходимые поля по списку id
  getFormsItemsByIds(forms, ids) {
    const allItems = {};
    let rootFormId;
    for (const { id, parentId, form } of forms) {
      allItems[id] = this.getFormItems(form);
      if (!parentId) rootFormId = id;
    }
    const resItems = [];
    for (const key of ids) {
      const parsedKey = this.parseFormItemKey(key);
      const formItems = parsedKey.formId
        ? allItems[parsedKey.formId]
        : allItems[rootFormId];
      const findItem = formItems.find((i) => i.id === parsedKey.itemId);
      if (findItem) resItems.push({ ...findItem, dataKey: key });
    }
    return resItems;
  },

  // Скролл к секции формы
  scrollToFormSection(container, formId, sectionIndex) {
    if (!container) return;
    const sectionId = formId ? `${formId}_${sectionIndex}` : sectionIndex;
    const sectionEl = container.querySelector(`#section_${sectionId}`);
    const sectionToggleEl = container.querySelector(`#toggle_${sectionId}`);
    const elToScroll = sectionToggleEl || sectionEl;
    if (!elToScroll) {
      container.scrollTo(0, 0);
      return;
    }
    // если секция уже активна, но свернута - клик для раскрытия
    const style = window.getComputedStyle(elToScroll.parentElement);
    const isCollapsed = style.display === 'none';
    if (isCollapsed) sectionToggleEl.children[0].click();
    elToScroll.scrollIntoView();
  },
};

export default FormUtils;
