import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

function useTypingStatus(actorId, actionType, delay = 2000) {
  const dispatch = useDispatch();
  const [isTyping, setIsTyping] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const setTypingStatus = (action) => {
    setIsTyping(action);
    dispatch({
      type: actionType,
      payload: { actorId, action },
    });
  };

  // Debounced function to detect when typing stops
  const handleStopTyping = useCallback(() => {
    setTypingStatus(false);
  }, []);

  // Function to trigger when user types
  const handleTyping = useCallback(() => {
    if (!isTyping) {
      setTypingStatus(true);
    }

    // Clear the existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout to detect when the user stops typing
    const newTimeoutId = setTimeout(handleStopTyping, delay);
    setTimeoutId(newTimeoutId);
  }, [isTyping, timeoutId, handleStopTyping, delay]);

  return { isTyping, handleTyping };
}

export default useTypingStatus;
