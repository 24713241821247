import { Utils } from 'mw-style-react';

export const CREATE_LAYER = Utils.createRequestTypes('CREATE_LAYER');
export const UPDATE_LAYER = Utils.createRequestTypes('UPDATE_LAYER');
export const REMOVE_LAYER = Utils.createRequestTypes('REMOVE_LAYER');
export const GET_LAYER = Utils.createRequestTypes('GET_LAYER');
export const MANAGE_LAYER_ACTORS = Utils.createRequestTypes(
  'MANAGE_LAYER_ACTORS'
);
export const MAKE_AUTO_LAYER_PAGE = Utils.createRequestTypes(
  'MAKE_AUTO_LAYER_PAGE'
);
export const LAYER_ACCESS = Utils.createRequestTypes('LAYER_ACCESS');
export const PIN_LAYER = Utils.createRequestTypes('PIN_LAYER');
export const UNPIN_LAYER = Utils.createRequestTypes('UNPIN_LAYER');
export const CLOSE_LAYER = Utils.createRequestTypes('CLOSE_LAYER');
export const MOVE_LAYER = Utils.createRequestTypes('MOVE_LAYER');
export const SAVE_LAYER_POSITION = Utils.createRequestTypes(
  'SAVE_LAYER_POSITION'
);
export const SET_LAYER_SETTINGS =
  Utils.createRequestTypes('SET_LAYER_SETTINGS');
export const GET_LAYER_BALANCE = Utils.createRequestTypes('GET_LAYER_BALANCE');
export const STARRED_LAYER_ACTOR = Utils.createRequestTypes(
  'STARRED_LAYER_ACTOR'
);

export const GRAPH_DISCOVERY = {
  LOAD_SECTOR: Utils.createRequestTypes('GRAPH_DISCOVERY_LOAD_SECTOR'),
  LOAD_WHOLE_SKELETON: Utils.createRequestTypes(
    'GRAPH_DISCOVERY_LOAD_WHOLE_SKELETON'
  ),
  CLEAR: Utils.createRequestTypes('GRAPH_DISCOVERY_CLEAR'),
  MAKE_TRACE: Utils.createRequestTypes('GRAPH_DISCOVERY_MAKE_TRACE'),
};

export const GET_ACTOR_LAYER = Utils.createRequestTypes('GET_ACTOR_LAYER');
export const GET_TREE_LAYER = Utils.createRequestTypes('GET_TREE_LAYER');
export const SET_LAYER_PICTURE = Utils.createRequestTypes('SET_LAYER_PICTURE');
export const SET_ACTIVE_LAYER = Utils.createRequestTypes('SET_ACTIVE_LAYER');
export const EDITABLE_NODE = Utils.createRequestTypes('EDITABLE_NODE');
export const SAVE_ACTOR_LAYER_SETTINGS = Utils.createRequestTypes(
  'SAVE_ACTOR_LAYER_SETTINGS'
);

export const GET_VERTICAL_LINKS =
  Utils.createRequestTypes('GET_VERTICAL_LINKS');
export const SET_VERTICAL_LINKS =
  Utils.createRequestTypes('SET_VERTICAL_LINKS');
export const TOGGLE_VERTICAL_LINKS = 'TOGGLE_VERTICAL_LINKS';
export const GET_ALL_LAYERS = Utils.createRequestTypes('GET_ALL_LAYERS');
export const MANAGE_FOLDER_LAYERS = Utils.createRequestTypes(
  'MANAGE_FOLDER_LAYERS'
);
export const GET_LAYERS_COMMON_ACTORS = Utils.createRequestTypes(
  'GET_LAYERS_COMMON_ACTORS'
);
export const TOGGLE_ACTORS_LAYER_NUMBERS = Utils.createRequestTypes(
  'TOGGLE_ACTORS_LAYER_NUMBERS'
);

export const TOGGLE_NODES_COORDINATES = Utils.createRequestTypes(
  'TOGGLE_NODES_COORDINATES'
);

export const ADD_LAYERS_AREA = Utils.createRequestTypes('ADD_LAYERS_AREA');
export const CLEAR_NEW_LAYER_AREAS = Utils.createRequestTypes(
  'CLEAR_NEW_LAYER_AREAS'
);

export const GET_ACTOR_LINKS = Utils.createRequestTypes('GET_ACTOR_LINKS');
export const ACTOR_DECOMPOSE = Utils.createRequestTypes('ACTOR_DECOMPOSE');
export const ACTORS_AGGREGATION =
  Utils.createRequestTypes('ACTORS_AGGREGATION');

export const GET_TRANSFERS_EDGES = Utils.createRequestTypes(
  'GET_TRANSFERS_EDGES'
);

export const GET_LAYER_STATS = Utils.createRequestTypes('GET_LAYER_STATS');

export const SET_LAYERS_REQ_STATUS = 'SET_LAYERS_REQ_STATUS';
export const CLEAR_LAYERS = 'CLEAR_LAYERS';
export const EXPANDED_LAYER_LOADED = 'EXPANDED_LAYER_LOADED';
export const SET_MAX_LAYER_NUMBER = 'SET_MAX_LAYER_NUMBER';

export const SYSTEM_SPECIAL_LAYERS = [
  'graphRules',
  'accounts',
  'dashboards',
  'vertical',
];

export const TYPE_LAYER = {
  layers: 'layers',
  trees: 'trees',
  actors: 'actors',
};

export const SYSTEM_LAYERS_NAMES = {
  autoDoc: 'autoDoc',
  graphActorBag: 'graphActorBag',
  actorBag: 'actorBag',
  processes: 'processes',
  eventStream: 'eventStream',
  smartForms: 'smartForms',
  dashboards: 'dashboards',
  verticalLayer: 'verticalLayer',
  accounts: 'accounts',
  accessMap: 'accessMap',
  smartContract: 'smartContract',
};
