import { useEffect } from 'react';
import { throttle, keyBy, mapValues } from 'lodash';
import {
  snapGeomCoord,
  keyByPosition,
  isIntersectionsAllowed,
  moveNodes,
  getNodeSnapType,
  NODE_SNAP_TYPE,
  getGraphCursorPosition,
} from '@control-front-end/utils/modules/utilsCellCoords';
import AppUtils from '@control-front-end/utils/utils';
import { getNodesInState } from '@control-front-end/utils/modules/utilsStateMarkup';
import { CUSTOM_EVENTS } from '@control-front-end/common/constants/graph';

const THROTTLE_TIME_FOR_OPTIMAL_DRAG = 50;

const moveNodesThrottled = throttle(moveNodes, THROTTLE_TIME_FOR_OPTIMAL_DRAG);

const useGridSnap = (cy) => {
  useEffect(() => {
    if (!cy) return;

    let cursorPosition;
    let dragStart;
    let nodesStartPositions = {};
    let occupiedPositions = {};
    let nodesToDrag = [];
    let canSnap = true;
    let dragged = false;

    const getPosition = (position) =>
      canSnap ? snapGeomCoord(position) : position;

    const moveNodesByCursor = ({ throttle = false, callback }) => {
      if (!cursorPosition) return;

      const positionShift = {
        x: cursorPosition.x - dragStart.x,
        y: cursorPosition.y - dragStart.y,
      };

      if (!dragged && positionShift.x === 0 && positionShift.y === 0) return;

      (throttle ? moveNodesThrottled : moveNodes)({
        cy,
        nodes: nodesToDrag,
        occupiedPositions,
        positionShift,
        checkZeroShift: !dragged,
        nodesStartPositions,
        callback: (...args) => {
          dragged = true;
          callback?.(...args);
        },
      });
    };

    const onTapDrag = (e) => {
      const newCursorPosition = getPosition(getGraphCursorPosition(cy, e));
      if (
        newCursorPosition.x === cursorPosition?.x &&
        newCursorPosition.y === cursorPosition?.y
      ) {
        return;
      }

      cursorPosition = newCursorPosition;

      moveNodesByCursor({ throttle: true });
    };

    const onDragFree = () => {
      cy.off('tapend', onDragFree);
      cy.off('tapdrag', onTapDrag);

      moveNodesByCursor({
        callback: ({ nodes }) => {
          nodes.forEach((node) => {
            if (!node.data('isStateMarkup')) return;
            node.data(
              'polygon',
              AppUtils.snapPolygonToNewCenter(
                node.data('polygon'),
                node.position()
              )
            );
          });
        },
      });

      nodesToDrag.forEach((node) => {
        node.unlock();
        setTimeout(() => {
          node.selectify();
        }, 0);
        node.trigger('free');
        if (dragged) node.trigger('dragfree');
      });
      if (dragged) {
        cy.trigger(CUSTOM_EVENTS.dragfree_bulk, cy.collection(nodesToDrag));
      }

      dragged = false;
      cursorPosition = null;
      nodesToDrag = [];
    };

    const onTapStart = (e) => {
      const cyTarget = e.target || e.cyTarget;

      if (
        !cyTarget.grabbable() ||
        cyTarget.locked() ||
        e.originalEvent.shiftKey
      )
        return;

      if (!cyTarget.selected()) {
        cy.$(':selected').unselect();
        cyTarget.select();
        cyTarget.grabify();
      }

      const selectedNodes = cy.$(':selected');

      dragged = false;
      nodesToDrag = [];

      selectedNodes.forEach((node) => {
        if (!node.grabbable() || node.locked() || node.data('isNonInteractive'))
          return;
        nodesToDrag.push(node);
        if (node.data('isStateMarkup')) {
          const innerStateNodes = getNodesInState({
            stateNode: node,
            cy,
          });
          innerStateNodes.select();
          innerStateNodes.forEach((node) => {
            nodesToDrag.push(node);
            node.trigger('grab');
            cy.trigger('tapstart', node);
          });
        }
      });

      canSnap = true;
      // Enable free dragging for single, non-snapping node
      if (
        selectedNodes.length === 1 &&
        getNodeSnapType(selectedNodes[0].data()) === NODE_SNAP_TYPE.none
      ) {
        canSnap = false;
      }

      dragStart = getPosition(getGraphCursorPosition(cy, e));

      // Remember dragged nodes initial positions
      nodesStartPositions = {};
      nodesToDrag.forEach((node) => {
        nodesStartPositions[node.id()] = {
          x: node.position('x'),
          y: node.position('y'),
        };
        node.unselectify();
        node.lock();
        node.trigger('grab');
      });

      cy.trigger(CUSTOM_EVENTS.grab_bulk, cy.collection(nodesToDrag));

      occupiedPositions = mapValues(
        keyBy(
          cy
            .nodes(':unselected')
            .filter((node) => !isIntersectionsAllowed(node)),
          (node) => keyByPosition(node.position())
        ),
        () => true
      );

      cy.on('tapdrag', onTapDrag);
      cy.on('tapend', onDragFree);
    };

    cy.on('tapstart', 'node', onTapStart);

    return () => {
      cy.off('tapstart', 'node', onTapStart);
      cy.off('tapdrag', onTapDrag);
      cy.off('tapend', onDragFree);
    };
  }, [cy]);
};

export default useGridSnap;
